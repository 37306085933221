import { Injectable } from "@angular/core";
import { AsyncSubject, Observable, Subject, ReplaySubject } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "./http.service";
import { Router } from "@angular/router";
import * as signalR from "@microsoft/signalr";
import { AuthenticationService } from "src/app/core/auth/authentication.service";
import { MessageRecipientDto } from "../model/message-recipientDto";
import { MessageDto } from "../model/messages/message";
import { MessageReply } from "../model/messages/message-reply";
import { MessageRoomDto } from "../model/messages/message-room.dto";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(
    private _httpService: ApiService,
    public router: Router,
    public authService: AuthenticationService
  ) {}

  private roomMessages = new AsyncSubject();
  private allPrivateRooms = new AsyncSubject();
  hubConnection?: signalR.HubConnection;
  messageHubConnectionId: string | any;
  users: MessageRecipientDto[] = [];

  msSubject = new Subject<any>();
  msObservable(): Observable<any> {
    return this.msSubject.asObservable();
  }
  
  startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.messageHub, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.closeMessageHubConnectionListen();
        this.msSubject.next({ type: "MessageHubStarted" });
      })
      .catch((err) => console.log("Error while starting connection: " + err));
    console.log(`HubConnection.state: ${this.hubConnection?.state}`);
    setTimeout(() => {
      console.log(`HubConnection.state: ${this.hubConnection?.state}`);
    }, 2000);
  };


  closeMessageHubConnectionListen(): void {
    this.hubConnection?.on("closeMessageuHubConnectionResponse", () => {
      location.reload();
      console.log(`HubConnection.state: ${this.hubConnection?.state}`);
    });
  }

  logOutInvoke(): void {
    console.log(`disconnecting MessageHub`);
    this.hubConnection
      ?.invoke("CloseConnection")
      .catch((err) => console.error(err));
  }

  setAsyncSubjectsToNull() {
    this.roomMessages.next(null);
    this.roomMessages.complete();
    this.allPrivateRooms.next(null);
    this.allPrivateRooms.complete();
  }

  getRoomMessages(): Observable<any> {
    return this.roomMessages.asObservable();
  }

  setAllPrivateRooms(rooms: MessageRoomDto[]) {
    this.allPrivateRooms.next(rooms);
    this.allPrivateRooms.complete();
  }

  getAllPrivateRooms(): Observable<any> {
    return this.allPrivateRooms.asObservable();
  }


  addAndRemoveMessageRoomLike(like: any): Observable<any> {
    const url = environment.messages.addAndRemoveMessageRoomLike;
    const queryParams = {};
    return this._httpService.Post<any>(url, like);
  }

  getUserPrivateRooms() {
    const url = environment.messages.getMessagesRoomsUserIsIn;
    this._httpService.Get<any>(url).subscribe((res) => {
      this.setAllPrivateRooms(res);
    });
  }

  getAllMessagesInRoom(id: number): Observable<MessageDto[]> {
    const url = environment.messages.getAllMessagesInRoom;
    console.log("getting messages: ", id);
    const data = { id: id };
    return this._httpService.Post(url, data);
  }
  replyToMessage(messageReply: MessageReply): Observable<any> {
    const url = environment.messages.replyToMessage;
    return this._httpService.Post(url, messageReply);
  }

  getSharedRoom(participantId: number): Observable<MessageRoomDto[]> {
    const url = `${environment.messages.getMessageRoomsForUsers}?participantId=${participantId}`;
    return this._httpService.Get<MessageRoomDto[]>(url);
  }
}
