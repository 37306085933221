import { postTypes } from "../model/posts/postTypes";
import { StorageType } from "../types/StorageType";
export function parsePathToType(value: string) {
  switch (value) {
    case "event":
      return postTypes.events;
    case "group":
      return postTypes.group;
    case "training":
      return postTypes.training;
    case "animalFriendly":
      return postTypes.animalFriendly;
    case "":
    case 'start':
      return postTypes.none;
    default:
      return postTypes.none;
  }
}



export const readFromStorage = (storage: Storage, keyName: string) => {
  const cache = storage.getItem(keyName);
  const cacheIsNull = cache === null;
  if (!cacheIsNull && cache !== undefined) {
    return JSON.parse(cache!)
  }
  else return undefined;
}

export const saveToStorage = (storage: Storage, keyName: string, data: any) => {
  try {
    const string = JSON.stringify(data);
    storage.setItem(keyName, string);
  return true;
  } catch{
    return false;
  }
}
