import { Address } from "src/app/model/address";
import { Friendship } from "./friendship";
export class User {
  public id: number;
  public socialSecurityNumber: string;
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public email: string;
  public password: string;
  public phoneNumber: string;
  public mobilePhoneNumber: string;
  public agreeMarketing: boolean;
  public subscribeToEmailNotification: boolean;
  public profileImage: string;
  public address: Address;
  public gender: string;
  friendship: Friendship | null;
  havePet: boolean;
  petNum: number;
  petType: string;

  constructor(data: Partial<User>) {
    Object.assign(this, data);
  }

  public static empty(): User {
    return new User({
      id: 0,
      socialSecurityNumber: "",
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      password: "",
      phoneNumber: "",
      mobilePhoneNumber: "",
      gender: "",
      havePet: false,
      petNum: 0,
      petType: "",
      agreeMarketing: false,
      subscribeToEmailNotification: false,
      profileImage: "",
      address: Address.empty(),
      friendship: null,
    });
  }
}
