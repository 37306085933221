import { inject, Injectable } from "@angular/core";
import {
  Observable,
  BehaviorSubject,
  Subject,
  AsyncSubject,
  throwError,
  firstValueFrom,
  lastValueFrom,
} from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map, tap } from "rxjs/operators";
import { User } from "src/app/shared/model/user";
import { UpdateUser } from "src/app/shared/model/update-user";
import { Register } from "src/app/shared/model/register";
import { ApiService } from "src/app/shared/service/http.service";
import { MessageService } from "src/app/shared/service/message.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  httpService = inject(ApiService);
  messageService = inject(MessageService)


  private userAsyncSubject = new AsyncSubject();
  private randomUserSubject = new Subject();

  setUserAsyncSubject(user: User) {
    this.userAsyncSubject.next(user);
    this.userAsyncSubject.complete();
  }

  getUserAsyncSubject(): Observable<User> {
    return this.userAsyncSubject
      .asObservable()
      .pipe(map((value) => value as User));
  }



    async refreshUserObject(): Promise<User> {
      const user = await lastValueFrom(this.httpService.Get<User>(environment.userBaseURL));
      this.setUser(user);
      return user;
  }

  getRandomUserSubject(): Observable<any> {
    return this.randomUserSubject.asObservable();
  }

  getUserInfo(userId?: number) {
    const append = userId !== undefined ? userId.toString() : "";
    let url = `${environment.userBaseURL}` + append;
    this.httpService.Get(url).subscribe((data) => {
      if (userId) {
        this.randomUserSubject.next(data);
      } else {
        this.userAsyncSubject.next(data);
        this.userAsyncSubject.complete();
      }
    });
  }

  returnUserInfo(userId?: number): Observable<User> {
    const id = userId ? userId : 0;
    const url = `${environment.userBaseURL}/${id}`;
    return this.httpService.Get<any>(url);
  }

  setUserToNull() {
    this.userAsyncSubject.next(null);
    this.userAsyncSubject.complete();
    this.messageService.setAsyncSubjectsToNull();
  }

  //////////////////

  private userSource: BehaviorSubject<User> = new BehaviorSubject<User>(
    User.empty()
  );
  public currentUser: Observable<User> = this.userSource.asObservable();

  /**
   * Set logged in user.
   */
  public setUser(user: User) { this.userSource.next(user); }

  /**
   * Get current value from Observable without subscribing (just want value one time).
   */
  public getCurrentUser() {
    return this.userSource.asObservable().pipe(map((value) => value as User));
  }

  public getCustomerUserProfile() {
    this.getAuthenticatedUser().subscribe((res) => {
      if (res) {
        this.setUser(res);
      }
    });
  }

  /**
   * Gets user from the server.
   */
  public getAuthenticatedUser(): Observable<User> {
    return this.httpService.Get<User>(environment.get_user_url);
  }

  /**
   * A call to update the customer profile with new data. THis method returns an observable, however, the
   * result is also published in the Observable userSource. So if the client already subscribes the new User
   * data by subscribing to userSource, that client will be notified once this operation has succeeded.
   */
  updateCustomerProfile(updateUser: UpdateUser): Observable<User> {
    return this.httpService
      .Post<User>(environment.update_user_url, updateUser)
      .pipe(
        tap((res) => {
          if (res) {
            this.setUser(res);
          }
        }),
        catchError((error) => throwError(error))
      );
  }

  public checkUser() {
    if (this.userSource.value) {
      return true;
    } else {
      return false;
    }
  }

  getUserData(socialSecurityNr: string): Observable<Register> {
    return this.httpService.Get<any>(`${environment.getUserData}/${socialSecurityNr}`);
  }

  public addUser(registerData: Register): Observable<any> {
    return this.httpService.Post<Register>(environment.register_url, registerData);
  }

  updateUser(registerData: Register): Observable<User> {
    return this.httpService.Post<User>(environment.updateUser, registerData);
  }
}
