
export const postTypes = {
  group: "group",
  training: "training",
  animalFriendly: "animalFriendly",
  events: "events",
  none: "none",
} as const;

export const postTypeMapping = {
  group: 1,
  training: 2,
  animalFriendly: 3,
  events: 4,
  none: 5,
} as const;

export type PostTypes = typeof postTypes[keyof typeof postTypes];
export type PostTypeMapping = keyof typeof postTypeMapping;
