import {inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable, of} from "rxjs";
import { ApiService } from "src/app/shared/service/http.service";
import {environment} from "src/environments/environment";
import {debounceTime, map, tap} from "rxjs/operators";
import { NewPost, PostType } from "src/app/shared/model/posts/new-post";
import { Post } from "src/app/shared/model/posts/post";
import { PostTypes, postTypes } from "src/app/shared/model/posts/postTypes";
import { saveToStorage } from "src/app/shared/utils/functions";

@Injectable({
  providedIn: "root",
})


export class PostsService {

  restoreCache(storedPosts: Map<string, Post[]>) {
    this.cache = storedPosts as Map<string, Post[]>;
  }

  saveCache(){
    const obj = Object.fromEntries(this.cache);
    saveToStorage(localStorage,"postCache", obj);
  }

  private cache = new Map<string, Post[]>();
  private allPostsSource: BehaviorSubject<Post[]> = new BehaviorSubject<Post[]>([]);
  public allPosts: Observable<Post[]> = this.allPostsSource.asObservable();

  apiService = inject(ApiService);

set(key: string, data:Post[]): void{
  this.cache.set(key,data);
  this.saveCache();
  this.allPostsSource.next(this.cache.get(key)!);
}

get(key: string) : Post[] {
  const data = this.cache.get(key) ?? [];
  this.allPostsSource.next(data);
  return this.allPostsSource.value;
}

clear(key:string) : void {
  this.cache.delete(key);
  this.allPostsSource.next([]);
}

  getAllPostsAsync(userId: number, type: PostTypes = postTypes.none): Observable<Post[]> {
    return this.getPostsFromApi(userId, type).pipe(tap((posts) => { this.set(type,posts)}));
  }

  private getPostsFromApi = (authorId : number, type: PostTypes) => this.apiService.Get<Post[]>(`${environment.posts.getAllPosts}/${type}`, {params: {authorId: authorId}})

  getUserPostsAsync(userId: number, type: PostTypes): Observable<Post[]> {
    return this.getPostsFromApi(userId, type).pipe(map((posts) => posts.filter(post => post.userId === Number(userId))));
  }

  createPost(newPost: NewPost) {
    console.log("newPost: ", newPost);
    return this.apiService.Post<NewPost>(environment.posts.createPost, newPost);
  }

  public setAllPosts(posts: Post[]): void {
    this.allPostsSource.next(posts);
  }
  

  public getAllPosts(): Post[] {
    return this.allPostsSource.value;
  }
  
  public clearPostsCache() {
    this.allPostsSource.next([]);
  }
}

